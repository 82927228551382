import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function() {
    const swiper = new Swiper(".swiper", {
        modules: [Navigation, Pagination, Autoplay],
        direction: "horizontal",
        parallax: true,
        loop: true,
        effect: "fade",
        autoplay: {
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },

        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },

        on: {
            init: function () {
              // Agrega la clase 'show' al contenido del slide activo al inicio
              const activeSlide = this.slides[this.activeIndex];
              const slideContent = activeSlide.querySelector('.slide-content');
              if (slideContent) {
                slideContent.classList.add('show');
              }
            },
            slideChangeTransitionStart: function () {
              // Resetea la clase 'show' en todos los slides
              const slides = document.querySelectorAll('.swiper-slide');
              slides.forEach((slide) => {
                const slideContent = slide.querySelector('.slide-content');
                if (slideContent) {
                  slideContent.classList.remove('show');
                }
              });
            },
            slideChangeTransitionEnd: function () {
              // Agrega la clase 'show' al contenido del slide activo después de la transición
              const activeSlide = this.slides[this.activeIndex];
              const slideContent = activeSlide.querySelector('.slide-content');
              if (slideContent) {
                slideContent.classList.add('show');
              }
            },
        },
    });
});