/**
* Template Name: Arsha
* Updated: Jul 27 2023 with Bootstrap v5.3.1
* Template URL: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

import AOS from "aos";

(function() {
  "use strict";
  /**
   * Animation on scroll
   */
  window.addEventListener('load', () => {
    AOS.init({
      duration: 2000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  });

})()