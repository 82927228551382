document.addEventListener('DOMContentLoaded', function() {
    const tabLinks = document.querySelectorAll('.tab-link');
    const addons = document.querySelectorAll('.addon');
    const tabsContent = document.querySelectorAll('[id^="tabs-"]');
  
    function setActiveTab(index) {
      for (let i = 0; i < tabLinks.length; i++) {
        if (i === index) {
          tabLinks[i].classList.add('active');
          addons[i].classList.add('fadein');
          tabsContent[i].style.display = 'block'; // Mostrar contenido del tab seleccionado
        } else {
          tabLinks[i].classList.remove('active');
          addons[i].classList.remove('fadein');
          tabsContent[i].style.display = 'none'; // Ocultar contenido de los demás tabs
        }
      }
    }
  
    for (let i = 0; i < tabLinks.length; i++) {
      tabLinks[i].addEventListener('click', function(e) {
        e.preventDefault();
        setActiveTab(i);
      });
    }
  
    // Optional: Add active class to the first tab and show the corresponding addon
    setActiveTab(0);
});